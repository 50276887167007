import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,


	Typography,
	TextField,
	Button,
	Grid,
	Box,
	Divider,


	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {Formik} from "formik";
import cryptoNetworks from "../../../../../../constants/cryptoNetworks";

const pageInitForm = {
	amount: undefined,

	usdt_summ: undefined,
	wallet: "",
	comment: "",
};

class DialogTopUpBalance extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			initForm: {},

			userId: null,

			open: false,
			isCreated: true,


			eventSubmit: null
		}

		this.refFormik = React.createRef();
	}


	onSubmit = (form) => {
		if (!form.usdt_summ || !form.wallet) {
			delete form.usdt_summ;
			delete form.wallet;
			delete form.comment;
		}

		this.state.eventSubmit(this.state.userId, form);
	}
	changeForm = ({target}) => {
		const {name, value} = target;

		let newForm = {...this.refFormik.current.values};
		newForm[name] = value;

		this.refFormik.current.setValues(newForm);
	}

	open = async ({initForm, eventSubmit, userId} = {}) => {
		await this.setState({
			open: true,
			eventSubmit,
			userId
		});

		this.refFormik.current.setValues(initForm || pageInitForm);
	}
	close = () => {
		this.setState({
			open: false
		})
	}

	onSuccess = () => {
		this.refFormik.current.handleSubmit()
	}
	onCancel = () => {
		this.close()
	}

	render() {
		const {open} = this.state;

		return (
			<Dialog
				open={open}
				maxWidth="md"
				fullWidth={true}


				onClose={this.onCancel}
			>

				<DialogTitle sx={{backgroundColor: "rgba(251, 120, 0, 1)"}}>
					<Typography variant="h3" sx={{color: "white"}}>
						Пополнение баланса
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Box px={1} py={3}>
						<Formik
							innerRef={this.refFormik}
							initialValues={this.state.initForm}
							validationSchema={validationSchema}
							onSubmit={this.onSubmit}
						>
							{(props) => {
								const {
									values,
									errors,
									touched,
									handleSubmit,
								} = props;

								return (
									<Box>
										<form
											onSubmit={handleSubmit}
											onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
										>

											<Box>
												<TextField
													value={values.amount}
													error={Boolean(touched.amount && errors.amount)}
													helperText={touched.amount && errors.amount}
													name="amount"
													type="number"
													label="Сумма пополнения"
													placeholder="Введите сумму пополнения"
													variant="outlined"
													fullWidth

													onChange={this.changeForm}
												/>
											</Box>



											<Box my={2}>
												<Divider/>
											</Box>

											<Grid container spacing={1}>
												<Grid item>
													<Typography variant="h4">Криптовалюта</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box>
														<TextField
															value={values.usdt_summ}
															error={Boolean(touched.usdt_summ && errors.usdt_summ)}
															helperText={touched.usdt_summ && errors.usdt_summ}
															name="usdt_summ"
															type="number"
															size="small"
															label="Сумма USDT"
															placeholder="Введите сумму USDT"
															variant="outlined"
															fullWidth

															onChange={this.changeForm}
														/>
													</Box>
												</Grid>
												<Grid item xs={12}>
													<FormControl fullWidth size="small">
														<InputLabel>Сеть</InputLabel>
														<Select
															value={values.wallet}
															label="Сеть"
															name="wallet"
															onChange={this.changeForm}
														>
															{cryptoNetworks.map((item) => (
																<MenuItem value={item.value}>{ item.label }</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<TextField
														value={values.comment}
														name="comment"
														multiline
														fullWidth
														variant="outlined"
														label="Комментарий"
														rows={4}

														onChange={this.changeForm}
													/>
												</Grid>
											</Grid>

										</form>
									</Box>
								)
							}}
						</Formik>
					</Box>
				</DialogContent>

				<DialogActions>
					<Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="outlined" onClick={this.onCancel}>
								Отмена
							</Button>
						</Grid>
						<Grid item>
							<Button sx={{borderRadius: "4px", textTransform: "initial"}} variant="contained" onClick={this.onSuccess}>
								Пополнить
							</Button>
						</Grid>
					</Grid>
				</DialogActions>

			</Dialog>
		)
	}
}

const validationSchema = Yup.object().shape({
	amount: Yup.number("Введите сумму").required("Введите сумму"),

	usdt_summ: Yup.number("Введите сумму").max(999999.99, 'Сумма не должна превышать: 999999.99'),
});

export default DialogTopUpBalance
