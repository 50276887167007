import React from "react";
import {
	Box,
	Grid,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Typography,
	Pagination,
	Skeleton,
	Avatar,
	Tooltip
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	TableHeadSortCell
} from "../../../../../../components";
import moment from "moment";
import {convertorNumber} from "../../../../../../helper/convertor";
import {useSelector} from "react-redux";

const TableCustom = (props) => {
	const {
		data,
		filter,
		pagination,

		isLoad,

		onChangeFilter,
		onChangePagination,
	} = props;
	const classes = useStyles();
	const userRole = useSelector(state => state?.global?.userRole);

	const handleChangePage = (event, page) => {
		let newPagination = {...pagination};
		newPagination.page = page;

		onChangePagination(newPagination)
	}
	const handleChangeSort = (sort) => {
		let newFilter = {...filter};
		newFilter.sort = sort;

		onChangeFilter(newFilter, true);
	}

	return (
		<>

			<Table>

				<TableHead>
					<TableRow>

						<TableHeadSortCell
							name="created_at"
							filterSort={filter.sort}
							label="Дата/время"

							onChange={handleChangeSort}
						/>
						<TableHeadSortCell
							label="Сумма"
							name="amount"
							filterSort={filter.sort}

							onChange={handleChangeSort}
						/>

						<TableCell>{(userRole === 'Bookkeeper') ? 'ID' : 'Email'} пользователя</TableCell>
						<TableCell>Тип (пополнение/списание/возврат)</TableCell>
						<TableHeadSortCell
							name="phone_number_id"
							filterSort={filter.sort}
							label="ИД транзакции"

							onChange={handleChangeSort}
						/>
						<TableCell>Способ оплаты</TableCell>

						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>

					{

						Boolean(isLoad) ? (

							<>

								{
									[0, 1, 2, 3, 4, 5].map((item) => (

										<TableRow key={`row-item-load-${item}`}>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell>
												<Skeleton/>
											</TableCell>
											<TableCell/>
										</TableRow>

									))
								}

							</>

						) : (

							<>

								{

									data.map((row, index) => (

										<TableRow key={`row-item-${index}`}>
											<TableCell>
												{moment(row.created_at).format("DD.MM.YYYY / HH:mm")}
											</TableCell>
											<TableCell>
												<Typography
													sx={{color: Boolean((row.amount || "")?.[0] === "-") ? "#DD4321" : "#379E3B"}}>
													{row.amount} ₽
												</Typography>
											</TableCell>
											<TableCell>
												{(userRole === 'Bookkeeper') ? row?.user?.cms_user?.id : row?.user?.cms_user?.email}
											</TableCell>
											<TableCell>
												{row.type}
											</TableCell>
											<TableCell>
												{row.external_id}
											</TableCell>
											<TableCell>
												{row.payment_type}

												{Boolean(row?.payment_options?.usdt_summ) && (
													<Box mt={1} border="1px solid rgb(0 0 0 / 10%)" borderRadius="10px" px="10px" py="4px">
														<Grid container flexDirection="column">
															<Grid item>
																<Typography variant="subtitle1">Криптовалюта</Typography>
															</Grid>
															<Grid item>Сумма USDT: <span style={{fontWeight: "bold"}}>{convertorNumber(row?.payment_options?.usdt_summ, 2, '.')}</span></Grid>
															<Grid item>Сеть: <span style={{fontWeight: "bold"}}>{row?.payment_options?.wallet}</span></Grid>
															<Grid item>Комментарий:
																<Tooltip arrow title={row?.payment_options?.comment || "Без комментарий"}>
																	<span style={{
																		maxWidth: 120,
																		whiteSpace: "nowrap",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		display: "block",
																		fontWeight: "bold"
																	}}>{row?.payment_options?.comment || "Без комментарий"}</span>
																</Tooltip>
															</Grid>
														</Grid>
													</Box>
												)}
											</TableCell>
											<TableCell align="right">

											</TableCell>
										</TableRow>

									))

								}

							</>

						)

					}

				</TableBody>

			</Table>

			<Box mt={3}/>

			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Pagination
						page={Number(pagination.page)}
						count={Number(pagination.totalPage)}

						onChange={handleChangePage}
					/>
				</Grid>
				<Grid item></Grid>
			</Grid>

		</>
	)
}

const useStyles = makeStyles((theme) => ({

	buttonAction: {}

}));

export default TableCustom
