import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table,
  Filter,
  DialogForm,
} from "./components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import moment from "moment";

const initFilter = {
  "filter[created_at][gt]": "",
  "filter[created_at][lt]": "",
  "filter[user_id]": "",
  "page": 1
}

class CompensationTg2fa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      filter: {...initFilter},
      isLoad: true,
      isBackdrop: false,
    };
    this.refDialogForm = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({ data: [], isLoad: true });
    const filter = this.getFilter();
    const response = await agent.get(`/smsgoapi/gonumbers/refund-list${filter}`).then((res) => {
      return res
    }).catch(() => {
      return null
    });

    this.setState({
      data: response?.data || [],
      isLoad: false
    });
  }

  getFilter = () => {
    const { filter } = this.state;

    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter[filterKey] || "";
      if (!!value) {
        list.push(`${filterKey}=${value}`)
      }
    });

    return `?${list.join("&")}`
  }
  changeFilter = async (filter, fastStart) => {
    await this.setState({ filter });
    if (fastStart) {
      await this.getData();
    }
  }
  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getData();
  }

  createCompensation = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createCompensation.bind(this)
      })

      return
    }

    this.setState({is: true});
    let body = [];
    Object.keys(form).map((t) => {
      body.push(`${t}=${form[t]}`)
    })
    const isSuccess = await agent.post(`/smsgoapi/gonumbers/refund-update?${body.join("&")}`).then((res) => {
      return true
    }).catch((err) => {
      return false
    });
    await this.getData();
    this.refDialogForm.current.close();
    Notification({
      type: Boolean(isSuccess) ? NotificationTypes.success : NotificationTypes.error,
      message: Boolean(isSuccess) ? "Успешно" : "Неуспешно"
    });
  }

  render() {
    const {
      data,
      filter,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>

        <Grid container alignItems="center" justifyContent="space-between" mb={1}>
          <Grid item>
            <Typography variant="h1">Компенсации TG 2fa</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{minHeight: "initial", height: "auto", padding: "5px 20px", borderRadius: "4px", textTransform: "initial"}}
              onClick={this.createCompensation}
            >
              Компенсировать
            </Button>
          </Grid>
        </Grid>

        <Filter
          filter={filter}
          onChange={this.changeFilter}
          onSearch={this.getData}
          onReset={this.resetFilter}
        />

        <Box mt={1}/>

        <Table
          data={data}
          isLoad={isLoad}
        />

        <DialogForm
          ref={this.refDialogForm}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default CompensationTg2fa
