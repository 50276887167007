import React, {useState} from "react";
import {
    Box,
    Grid,
    Typography,
    Button,

    Collapse,

    FormControl,
    InputLabel,
    Select,
    MenuItem,

    TextField
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Search as SearchIcon,
    Clear as ClearIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {
    FilterCountry,
    FilterAmountRange,
    FilterService,
    FilterUsers,
    FilterPaymentType,
    FilterDateRangeFull, FilterProvider
} from "../../../../../../components";
import {convertorDateToString, convertorNumber} from "../../../../../../helper/convertor";

const Filter = (props) => {
    const {
        paymentsType,

        filter,
        activeFilterCountry,
        initOpenFilter,
        totalCount,
        userCounts,

        onChange,
        onReset,
        onSearch,
        onChangeActiveFilterCountry
    } = props;
    const [isOpen, setOpen] = React.useState(true);
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(initOpenFilter || true);
    }, [initOpenFilter]);

    const handleChangeDateRange = (values) => {
        let newFilter = {...filter};

        newFilter["filter[created_at][gt]"] = values[0] || null;
        newFilter["filter[created_at][lt]"] = values[1] || null;

        onChange(newFilter);
    }
    const handleChangeAmountRange = (value) => {
        let newFilter = {...filter};

        newFilter["filter[amount][gt]"] = value[0];
        newFilter["filter[amount][lt]"] = value[1];

        onChange(newFilter);
    }
    const handleChangeCommon = ({target}) => {
        const {name, value} = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {

        let items = [];

        if (filter["filter[country]"]) {
            items.push({
                label: "Страна",
                value: activeFilterCountry,
                onClick: _deleteCommon.bind(this, "filter[country]")
            })
        }
        if (filter["filter[user_id]"]) {
            items.push({
                label: "ID пользователя",
                value: filter["filter[user_id]"],
                onClick: _deleteCommon.bind(this, "filter[user_id]")
            })
        }
        if (filter["filter[service]"]) {
            items.push({
                label: "Услуга",
                value: filter["filter[service]"],
                onClick: _deleteCommon.bind(this, "filter[service]")
            })
        }
        if (filter["filter[type]"]) {
            items.push({
                label: "Тип операции",
                value: filter["filter[type]"],
                onClick: _deleteCommon.bind(this, "filter[type]")
            })
        }
        if (filter["filter[phone_number_id]"]) {
            items.push({
                label: "ИД транзакции",
                value: filter["filter[phone_number_id]"],
                onClick: _deleteCommon.bind(this, "filter[phone_number_id]")
            })
        }
        if (filter["filter[created_at][gt]"] && filter["filter[created_at][lt]"]) {
            const startDate = convertorDateToString(filter["filter[created_at][gt]"]);
            const startEnd = convertorDateToString(filter["filter[created_at][lt]"]);

            items.push({
                label: "Дата",
                value: `${startDate} - ${startEnd}`,
                onClick: _deleteDateRange.bind(this, "filter[country]")
            })
        }
        if (filter["filter[amount][gt]"] && filter["filter[amount][lt]"]) {
            items.push({
                label: "Сумма",
                value: `${filter["filter[amount][gt]"]} ₽ - ${filter["filter[amount][lt]"]} ₽`,
                onClick: _deleteAmountRange.bind(this)
            })
        }


        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = {...filter};
        newFilter[name] = "";

        onChange(newFilter, true);
    }
    const _deleteDateRange = () => {
        let newFilter = {...filter};
        newFilter["filter[created_at][gt]"] = null;
        newFilter["filter[created_at][lt]"] = null;

        onChange(newFilter, true);
    }
    const _deleteAmountRange = () => {
        let newFilter = {...filter};
        newFilter["filter[amount][gt]"] = null;
        newFilter["filter[amount][lt]"] = null;

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4">
                        Фильтры

                        <Typography variant="body2" component="span" sx={{marginLeft: 1}}>всего найдено: {convertorNumber(totalCount)}</Typography>
                        <Typography variant="body2" component="span" sx={{marginLeft: 1}}>уникальных пользователей: {userCounts}</Typography>
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={() => setOpen(!isOpen)}
                                endIcon={<FilterAltIcon/>}
                            >
                                {isOpen ? "Свернуть" : "Развернуть"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onReset}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                endIcon={<SearchIcon/>}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>

                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>
                        {Boolean(false) && (
                          <>
                              <Grid item sm={3}>
                                  <FilterCountry
                                    name="filter[country]"
                                    value={filter["filter[country]"]}
                                    size="small"
                                    fullWidth

                                    onChange={handleChangeCommon}
                                    onChangeActiveFilterCountry={onChangeActiveFilterCountry}
                                  />
                              </Grid>
                              <Grid item sm={3}>
                                  <FilterService
                                    name="filter[service]"
                                    value={filter["filter[service]"]}
                                    size="small"
                                    fullWidth

                                    onChange={handleChangeCommon}
                                  />
                              </Grid>
                          </>
                        )}
                        <Grid item sm={3}>
                            <FilterPaymentType
                              name="filter[payment_type]"
                              value={filter["filter[payment_type]"]}
                              size="small"
                              fullWidth

                              onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterProvider
                              name="filter[provider_id]"
                              value={filter["filter[provider_id]"]}
                              size="small"
                              fullWidth

                              onChange={handleChangeCommon}
                            />
                        </Grid>

                        <Grid item sm={3}>
                            <FilterUsers
                                name="filter[user_id]"
                                value={filter["filter[user_id]"]}
                                size="small"
                                fullWidth
                                valueName="id"

                                onChange={handleChangeCommon}
                            />
                        </Grid>

                        <Grid item sm={3}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Тип операции</InputLabel>
                                <Select
                                    value={filter['filter[type]']}
                                    name="filter[type]"
                                    label="Тип операции"
                                    size="small"
                                    onChange={handleChangeCommon}
                                >
                                    <MenuItem value="">Сбросить</MenuItem>
                                    <MenuItem value="Пополнение баланса">Пополнение баланса</MenuItem>
                                    <MenuItem value="Списание">Списание</MenuItem>
                                    <MenuItem value="Возврат">Возврат</MenuItem>
                                    <MenuItem value=".">Все</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                value={filter["filter[phone_number_id]"]}
                                name="filter[phone_number_id]"
                                fullWidth

                                label="ИД транзакции"
                                size="small"

                                onChange={handleChangeCommon}
                            />
                        </Grid>
                        <Grid item sm={3} className={classes.dateTimePicker}>
                            <FilterDateRangeFull
                              value={[
                                filter['filter[created_at][gt]'],
                                filter['filter[created_at][lt]'],
                              ]}
                              onChange={handleChangeDateRange}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <FilterAmountRange
                                value={[filter["filter[amount][gt]"], filter["filter[amount][lt]"]]}

                                onChange={handleChangeAmountRange}
                            />
                        </Grid>

                        {Boolean(false) && (
                            <Grid item sm={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Способ оплаты</InputLabel>
                                    <Select
                                        value={filter['filter[payment_type]']}
                                        name="filter[payment_type]"
                                        label="Способ оплаты"
                                        size="small"
                                        onChange={handleChangeCommon}
                                    >
                                        {(paymentsType).map((payment, index) => (
                                            <MenuItem key={`paymentsType-${index}`} value={payment.id}>
                                                {payment.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Box>

            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1}/>

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{item.label}:</Typography>
                                    <Typography className={classes.activeValue}>{item.value}</Typography>
                                    <HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    );
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

    dateTimePicker: {
        "& .daterangepicker": {
            "& .applyButton": {
                backgroundColor: "rgba(251,120,0, .8) !important",
                border: "none !important",
                "&:hover": {
                    backgroundColor: "#FB7800 !important",
                }
            },
            "& .fromDateHourContainer": {
                border: "none !important",
            },
            "& .inputDate": {
                borderWidth: "2px !important",
                height: "33px !important",
                "&:focus": {
                    borderColor: "#FB7800 !important",
                    boxShadow: "none !important",
                }
            },
            "& .calendarAddon.input-group-addon": {
                borderWidth: "2px !important",
            },
            "& .multipleContentOnLine": {
                outline: "none !important",
                "& select": {
                    height: "33px !important",
                    borderRadius: "4px",
                    borderWidth: "2px",
                    outline: "none",
                    borderColor: "#ccc",
                    "&:focus": {
                        borderColor: "#FB7800 !important",
                        outline: "none !important",
                    }
                }
            },
            "& .monthYearContainer": {
                alignItems: "center",
            },
            "& .timeIconStyle": {
                top: "50%",
                transform: "translate(0, -50%)",
            },
            "& .glyphicon": {
                color: "#FB7800 !important"
            },
            "& .rangecontainer": {
                "& div": {
                    outline: "none !important",
                }
            },
            "& .calendarGrid": {
                "& div": {
                    outline: "none !important",
                }
            }
        }
    }

}));

export default Filter
