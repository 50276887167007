import React from "react";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Pagination,

  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import {convertorNumber} from "../../../../../../helper/convertor";
import agent from "../../../../../../agent/agent";
import {palette} from "../../../../../../theme/common";

class TableCustom extends React.PureComponent {
  render() {
    const {
      data,
      isLoad
    } = this.props;
    console.log('data: ', data);

    return (
      <>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Пользователь</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Расход по странам</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(isLoad) && <RowsLoading/>}
            {(data?.user_amounts || []).map((t, index) => <RowInfo key={`index-${index}`} {...t}/>)}
          </TableBody>
        </Table>

      </>
    )
  }
}
const RowInfo = React.memo((props) => {
  const {
    amount,
    user_id,
    countries
  } = props;
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    ( async () => {
      const response = await agent.get(`/smsgoapi/gousers?filter[id]=${user_id}`).then((res) => {
        return res.data?.[0]
      }).catch(() => {
        return {}
      });
      setUser(response);
    })();
  }, []);

  return (
    <TableRow>
      <TableCell>
        {Boolean(user) ? `${user?.email} (${user?.external_id})` : <Skeleton/>}
      </TableCell>
      <TableCell>
        {convertorNumber(amount)} ₽
      </TableCell>
      <TableCell>
        <Grid container spacing={1} direction="column">
          {Object.keys(countries).map((countryKey) => (
            <Grid item>
              { countryKey }: <span style={{color: palette.primary.main}}>{countries[countryKey]} ₽</span>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  )
});
const RowsLoading = React.memo(() => {
  return (
    <>{Array(10).fill(0).map((t) => (
      <TableRow>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
      </TableRow>
    ))}</>
  )
})

export default TableCustom
